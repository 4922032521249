@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
:root {
  --whiteColor: #fff;
  --headerHeight: 8rem;
  --primaryColor: #040052;
  --paragraphColor: #4b487a;
  --buttonColor: #5a50fe;
  --maxFormWidth: 40rem;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
}

h1,
h2,
h3 {
  margin: 0;
}

p {
  margin-bottom: 0;
}
