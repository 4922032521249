.bank-header h3 {
  text-align: center;
  font-weight: 700;
  line-height: 3rem;
  font-size: 2.5rem;
  margin: 2rem 0;
  color: #040052;
}

.bank {
  width: 40rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timer {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.timer p {
  color: #4b487a;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.timer p span {
  color: #040052;
  font-weight: 700;
}

.bank-details {
  margin-top: 2rem;
}
.receipient-details,
.bank-details {
  text-align: left;
}

.receipient-details div p,
.bank-details div p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #74748a;
}

.bank-details div p:last-child {
  color: #040052;
}

.receipient-details > div,
.bank-details > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 39.5rem;
}

.bank-header {
  margin: 1rem 0;
}

.bank-header p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #444052;
  font-weight: 700;
}

.account-number div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.account-number div p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #040052;
}

.account-number button {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #040052;
}

.view-more {
  color: #5a50fe;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.view-more p {
  margin: 0;
  color: #5a50fe !important;
}

.arrow-right {
  font-size: 2.4rem;
  margin-top: 0.3rem;
}

.warning {
  background-color: #fcf6de;
  padding: 2.4rem 1.6rem;
  border-radius: 1.2rem;
  width: 40rem;
}

.warning > div {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-self: center;
}

.warning > div img {
  height: 1.6rem;
  width: 1.6rem;
  margin-top: 0.3rem;
}

.bank-bottom {
  text-align: center;
  margin: 0 auto;
  margin: 2rem 0;
}
.bank-divider {
  margin: 7rem 0 3rem 0;
}

.bank-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin: 2rem 0;
}

.cancel a,
.less p {
  color: #5a50fe !important;
  font-weight: 700;
  line-height: 1.6rem;
  line-height: 2.4rem;
}

.less {
  margin-bottom: 4rem;
}

.extra-content {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.extra-content > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 39.5rem;
}

.bank-spinner {
  margin-top: 2rem;
}

.copy {
  height: 2rem;
  width: 2rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 74.8rem) {
  .bank div h3 {
    font-size: 2rem;
  }

  .bank,
  .warning {
    width: 32.5rem;
  }

  receipient-details > div,
  .bank-details > div,
  .extra-content > div {
    width: 32.5rem;
  }

  .receipient-details > div {
    width: 32.5rem;
    gap: 1rem;
  }
}
