.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 8.5rem;
}

.footer a {
  text-decoration: none;
  color: #55555e;
  font-size: 1.4rem;
  line-height: 2rem;
  transition: color 0.2s ease-in-out;
}

.footer a:hover {
  color: #5a50fe;
}