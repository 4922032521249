.otp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}
.otp-input {
  margin: 2rem 0;
}

.otp h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
}

.otp p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #444052;
  width: 35.1rem;
  text-align: center;
  margin-top: 1rem;
}

.otp div button:first-child,
.transaction-btn div button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  height: 5.6rem;
  background-color: #5a50fe;
  border-radius: 0.8rem;
  margin-top: 1rem;
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  width: 33.5rem;
}

.otp div button:first-child:hover,
.transaction-btn div button:hover,
.otp div button:first-child:active,
.transaction-btn div button:active,
.otp div button:first-child:focus,
.transaction-btn div button:focus {
  background-color: #5a50fe;
  color: white;
}

.resend {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.resend button {
  border: none;
  color: #5a50fe;
  background-color: white;
  cursor: pointer;
}

.ant-spin-dot-item {
  background-color: #5a50fe;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
  .otp div button:first-child,
  .transaction-btn div button {
    width: 40rem;
  }
}
@media only screen and (min-width: 1400px) {
}
