.pending-top h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
  max-width: 40rem;
}

.link-container {
  margin-top: 0;
}
