.amount form {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  width: 90vw;
  max-width: var(--maxFormWidth);
}

.amount .spin {
  text-align: center;
}

.amount-flag {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
}
.amount button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  height: 5.6rem;
  background-color: #5a50fe;
  border-radius: 0.8rem;
  margin-top: 1rem;
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
}

.amount button:hover,
.amount button:active,
.amount button:focus {
  background-color: #5a50fe;
  color: white;
  transition: 0.3s ease-in-out;
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2.4rem;
  background-color: #fcccd6;
  color: #a30010;
  border-radius: 1.2rem;
  margin-bottom: 1.5rem;
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  border-color: #cbcae6;
  border-radius: 8px 0px 0px 8px;
  padding: 1.6rem 2.4rem;
}

.ant-form-item-control-input-content .ant-input {
  border-radius: 8px;
  padding: 1.6rem 2.4rem;
}

.ant-form-item-control-input-content .ant-input:focus {
  border-color: #cbcae6;
  box-shadow: none;
}

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  border-radius: 8px 0px 0px 8px;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-radius: 0px 8px 8px 0px;
}

.ant-input-group-wrapper .ant-input {
  border-left: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: right;
}

.ant-input-group-wrapper .ant-input:focus {
  box-shadow: none;
}

.ant-input-group-wrapper .ant-input-group-addon {
  background-color: #ffffff;
}

.amount .ant-input-group-wrapper .ant-input {
  text-align: right;
  letter-spacing: 0.2rem;
}

.amount .ant-input-group-wrapper .ant-input::-webkit-outer-spin-button,
.amount .ant-input-group-wrapper .ant-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.amount form label {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #444052;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.enter-name {
  margin-top: 0rem !important;
}
