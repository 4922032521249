.paying {
  max-width: 40rem;
  margin: 0 auto;
}

.paying-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45rem;
}

.paying-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 3rem;
}

.paying-top img {
  text-align: center;
  height: 6.3rem;
  width: 6.3rem;
}

.paying-top h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
}

.paying-top p {
  color: #4b487a;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: center;
}

.paying-center {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  background-color: #fcf6de;
  border-radius: 0.8rem;
  gap: 1.5rem;
  margin: 2rem 0;
}

.paying-center div img {
  height: 2.6rem;
  width: 2.6rem;
  margin-top: 1rem;
}

.paying-center div p {
  margin: 0;
  color: #704f0f;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.paying-center div p:nth-child(1) {
  margin-bottom: 1rem;
}

.paying-bottom {
  background-color: #f4f6f7;
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  border-radius: 0.8rem;
}

.paying-bottom > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.paying-bottom > div p {
  margin: 0.8rem;
}

.paying-bottom > div p:nth-child(2) {
  font-weight: 700;
  color: #040052;
}

@media (max-width: 74.8rem) {
  .paying-bottom {
    max-width: 35rem;
  }

  .paying-first {
    width: 33.5rem;
  }

  .paying-top {
    margin-top: 5rem;
  }
}
