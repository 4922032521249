.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.divider div {
  height: 0.1rem;
  background-color: #e9ebed;
  width: 7.4rem;
}

.divider p {
  color: #55555e;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
}

@media (max-width: 74.8rem) {
  .divider p {
    text-align: center;
  }
}
