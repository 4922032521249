.home {
  min-height: 100vh;
  position: relative;
}

.home > div:first-child {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.home > div:first-child > div {
  min-height: 45rem;
  min-width: 100%;
  flex: 1;
}

.home > div:first-child > div:last-child {
  background-image: url(../../assets/hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 50vh;
}

.home > div:last-child {
  position: relative;
  top: var(--headerHeight);
  right: 0;
  left: 0;
  z-index: 2;
}
.home > div:last-child > .container > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: calc(100vh - var(--headerHeight));
}

.home > div:last-child > .container > div > div {
  min-width: 100%;
  flex: 1;
}

.hero-left-first h3 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.6rem;
  margin-bottom: 0;
  color: var(--primaryColor);
}

.hero-left-first p:first-child {
  color: #5a50fe;
  font-size: 1.4rem;
  line-height: 2rem;
  width: 13.3rem;
  background-color: #f9f5ff;
  text-align: center;
  border-radius: 1.6rem;
  padding: 0.4rem 1.2rem;
  letter-spacing: 0.1rem;
  margin: 1.5rem 0;
}

.hero-left p:last-child {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--paragraphColor);
  margin: 2.5rem 0;
}

.hero-left a {
  background-color: var(--buttonColor);
  color: var(--whiteColor) !important;
  padding: 1.6rem;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 0;
  display: inline-block;
  margin-top: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.hero-left a:hover {
  background-color: #0800A3;
}

.hero-left a img {
  margin-left: 1rem;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
  .home > div:first-child > div {
    min-width: auto;
  }

  .home > div:last-child > .container > div > div {
    min-width: auto;
  }

  .home > div:last-child > .container > div > div:first-child {
    padding-right: 5rem;
  }

  .home > div:last-child > .container > div {
    align-items: center;
  }
}
@media only screen and (min-width: 1400px) {
}
