.unsupported-content {
  text-align: center;
  width: 39.1rem;
  margin: 2.4rem 0 1rem 0;
}

.unsupported-content p {
  color: #4b487a;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.unsupported-content h3 {
  margin-bottom: 2.4rem;
  color: #040052;
}

.unsupported-link {
  margin-top: 2.4rem;
}

.unsupported-link a {
  color: #5a50fe;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
}

@media (max-width: 74.8rem) {
  .unsupported-content {
    width: 32.5rem;
  }
  .unsupported {
    margin-top: 5rem;
  }
}
