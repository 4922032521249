.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-container {
  margin: 2rem 0;
}

.profile-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.amount-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 3rem 0;
}

.ant-spin-dot-item {
  background-color: #5a50fe;
}

.unverified-alert {
  max-width: 55%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  margin: 4rem 0;
  background-color: #fcf6de;
  padding: 1.6rem;
  border-radius: 0.8rem;
}

.unverified-alert p {
  color: #966602;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-weight: 400;
}

.unverified-alert img {
  max-width: 2rem;
  margin-top: 0.5rem;
}

.unverified-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unverified-button a {
  text-align: center;
  background-color: #5a50fe;
  padding: 1.5rem 4rem;
  border-radius: 0.7rem;
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 40rem;
  transition: background-color 0.2s ease-in-out;
}

.unverified-button a:hover {
  background-color: #4a40f5;
}

@media (max-width: 74.8rem) {
  .unverified-alert {
    max-width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    margin: 2.8rem 0;
  }

  .unverified-alert img {
    max-width: 2rem;
  }

  .unverified-button a {
    max-width: 70%;
  }
}
