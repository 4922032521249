.confirm-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm {
  margin-top: 4rem;
}

.confirm > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 39rem;
}

.confirm > div p {
  color: #74748a;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.confirm-footer {
  margin-top: 4rem;
}

.confirm-top {
  color: #040052;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.confirm-top h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
}

.ant-spin-dot-item {
  background-color: #5a50fe;
}

@media (max-width: 74.8rem) {
  .confirm {
    width: 32.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
  }

  .confirm-top {
    padding-bottom: 1rem;
    margin-top: 6rem;
  }

  .confirm > div {
    width: 32.5rem;
  }

  .confirm-footer {
    margin-top: 2rem;
  }
}
