.App {
  /* margin: 0 0 0 4vw; */
  background-color: #ffffff;
  position: relative;
}

.container {
  display: table;
  margin: 0 auto;
  width: 90vw;
}

.page {
  width: 100%;
  overflow-x: hidden;
  padding-top: var(--headerHeight);
  min-height: 100vh;
}

.page-loader {
  width: 100vw;
  height: calc(100vh - (var(--headerHeight) * 2));
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  .paying,
  .success {
    margin-top: 10rem;
  }
}

@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 84vw;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1024px;
  }
}
