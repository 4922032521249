.success {
  margin-top: 7rem;
}
.success-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-top img {
  height: 5.5rem;
  width: 5.5rem;
}

.success-top h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
}

.link-container {
  margin-top: 2rem;
}

.link-container a,
.download p {
  color: #5a50fe;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.success-top p {
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #4b487a;
  max-width: 39rem;
}
