.profile-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}
.profile-intro p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: #6b6b80;
  height: 2.4rem;
  font-size: 1.6rem;
  margin: 0;
}

.profile-intro p span:first-child {
  margin-top: 0.4rem;
}

.profile-intro h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  margin: 1rem 0;
  color: var(--primaryColor);
}

.profile-intro img {
  height: 6.4rem;
  width: 6.4rem;
  border-radius: 50%;
  border: 4px solid #f4ebff;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
  .profile-intro h3 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
}
@media only screen and (min-width: 1400px) {
}
