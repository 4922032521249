.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.back span {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #4b487a;
}

.create-transaction img {
  width: 13rem;
  height: 12rem;
}

.create-transaction h3 {
  text-align: center;
  font-weight: 500;
  line-height: 4.4rem;
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #040052;
}

.create-transaction div label {
  color: #444052;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.create-transaction div input {
  width: 40rem;
  border: 1px solid #cbcae6;
  padding: 1.6rem 2.4rem;
  border-radius: 0.8rem;
}

.create-transaction div button {
  background: #e2e0ff;
}

.create-transaction div form {
  display: flex;
  flex-direction: column;
}

.ant-input-group-wrapper .ant-input {
  border-left: 0.1rem solid #d9d9d9;
  text-align: left;
}

@media (max-width: 74.8rem) {
  .create-transaction div form {
    width: 32.4rem;
  }

  .create-transaction div form input {
    width: 32.4rem;
  }
}
