.expired-content {
  margin-top: 3rem;
}

.expired-bottom,
.expired-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 3rem;
}

.expired-top {
  margin: 2rem 0;
}

.expired-top h3 {
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #444052;
  text-align: center;
  margin-bottom: 2rem;
}

.expired-top p,
.expired-content div p {
  color: #4b487a;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.expired-bottom p:first-child {
  color: #5a50fe;
  font-weight: 700;
  line-height: 1.6rem;
  line-height: 2.4rem;
}

.expired-bottom p:last-child {
  color: #55555e;
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (max-width: 74.8rem) {
  .expired-top h3 {
    font-size: 2rem;
  }

  .expired-top {
    margin-top: 2rem;
  }

  .expired-bottom,
  .expired-top {
    width: 32.5rem;
    margin: 0 auto;
    margin-top: 3rem;
  }
}
