.btn-container button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  padding: 1.6rem;
  height: 5.6rem;
  background: #5a50fe;
  border-radius: 0.8rem;
  margin-top: 3.2rem;
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  width: 33.7rem;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
  .btn-container button {
    width: 40rem;
  }
}
@media only screen and (min-width: 1400px) {
}
