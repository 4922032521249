.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--whiteColor);
  z-index: 10;
}

.header .container div {
  height: var(--headerHeight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header .container div img {
  max-height: calc(var(--headerHeight) * 0.4);
}
