.divider-container {
  margin-top: 3rem;
}

.phone {
  margin-top: 1rem;
}

.phone form label {
  color: #444052;
  font-size: 1.6rem;
  height: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.form-control {
  width: 40rem !important;
  height: 5.6rem !important;
  border-radius: 0.8rem !important;
}

.flag-dropdown,
.selected-flag {
  border-radius: 0.8rem 0rem 0rem 0.8rem !important;
}

.flag-dropdown:hover {
  border-radius: 0.8rem 0rem 0rem 0.8rem !important;
}

.flag {
  /* width: 1.5rem !important;
  height: 1.5rem !important; */
  height: 1.2rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
}

.phone .ant-input-group-wrapper .ant-input {
  text-align: left;
  letter-spacing: 0.2rem;
  -moz-appearance: textfield;
  margin: 0;
}

.phone form button {
  margin-top: 1.5rem !important;
  background-color: #5a50fe;
}

.phone .ant-space-vertical {
  margin-bottom: 1.5rem;
}

.phone form button:active,
.phone form button:focus {
  background-color: #5a50fe;
  color: white;
}

.phone-space-item {
  margin-top: 5rem !important;
}

.phone-description {
  color: #444052 !important;
  font-size: 1.6rem !important;
  line-height: 2.4rem !important;
}

.otp-method {
  display: flex;
  gap: 1.4rem;
  width: 100%;
}

.otp-method .ant-radio-button-wrapper-checked {
  border: 1px solid #5a50fe !important;
  box-shadow: rgba(90, 80, 254, 0.3) 0px 1px 2px 0px, rgba(90, 80, 254g, 0.15) 0px 1px 3px 1px !important;
}

.otp-method-whatsapp,
.otp-method-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding: 2.8rem 2rem;
  border-radius: 0.8rem !important;
  white-space: nowrap;
}

.otp-method-whatsapp span,
.otp-method-text span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.ant-radio-button-wrapper {
  border: 1px solid #cbcae6 !important;
  color: #444052 !important;
}

.otp-method-whatsapp img,
.otp-method-text img {
  height: 2.4rem;
  width: 2.4rem;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

@media (max-width: 74.8rem) {
  .phone {
    margin-top: 2rem;
  }

  .form-control {
    width: 32rem !important;
  }
}
